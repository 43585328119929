import * as React from 'react';
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from "@progress/kendo-react-charts";
import { Card } from '@progress/kendo-react-layout';

interface DataType {
    name: string;
    data: number[];
}

interface PieDataType {
    name: string;
    share: number;
    explode?: boolean;
}

const categories: number[] = [2002, 2003, 2004];
const series: DataType[] = [
    {
      name: 'India',
      data: [3.907, 7.943, 7.848],
    },
    {
      name: 'Russian Federation',
      data: [4.743, 7.295, 7.175],
    },
    {
      name: 'Germany',
      data: [0.21, 0.375, 1.161],
    },
    {
      name: 'World',
      data: [1.988, 2.733, 3.994],
    },
];

const areaData: DataType[] = [
    {
      name: 'World',
      data: [3.988, 3.733, 3.994],
    },
    {
      name: 'Germany',
      data: [2.21, 2.375, 2.161],
    },
    {
      name: 'Russian Federation',
      data: [1.743, 1.295, 1.175],
    },
    {
      name: 'India',
      data: [0.907, 0.943, 0.848],
    },
];

const pieData: PieDataType[] = [
    {
      name: 'India',
      share: 0.24,
    },
    {
      name: 'Russian Federation',
      share: 0.26,
      explode: true,
    },
    {
      name: 'Germany',
      share: 0.1,
    },
    {
      name: 'World',
      share: 0.4,
    },
];

const Chart_PageComponent = (props) => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                <div className="col-6">
                    <Card>
                        <Chart style={{ height: 350 }}>
                            {/*<ChartTooltip />  uncomment to enable Tooltips */}
                            <ChartTitle text="Column Chart" />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={categories} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {series.map((item, idx) => <ChartSeriesItem key={idx} type="column"
                                    tooltip={{ visible: true }} data={item.data} name={item.name} />)}
                            </ChartSeries>
                        </Chart>
                    </Card>
                </div>
                <div className="col-6">
                    <Card>
                        <Chart style={{ height: 350 }}>
                            <ChartTitle text="Line Chart" />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={categories} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {series.map((item, idx) => <ChartSeriesItem key={idx} type="line"
                                    tooltip={{ visible: true }} data={item.data} name={item.name} />)}
                            </ChartSeries>
                        </Chart>
                    </Card>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div className="col-6">
                    <Card>
                        <Chart style={{ height: 350 }}>
                            <ChartTitle text="Area Chart" />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={categories} startAngle={45} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {areaData.map((item, idx) => <ChartSeriesItem key={idx} type="area"
                                    tooltip={{ visible: true }} data={item.data} name={item.name} />)}
                            </ChartSeries>
                        </Chart>
                    </Card>
                </div>
                <div className="col-6">
                    <Card>
                        <Chart style={{ height: 350 }}>
                            <ChartTitle text="Pie Chart" />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartSeries>
                                <ChartSeriesItem type="pie" overlay={{ gradient: "sharpBevel" }}
                                    tooltip={{ visible: true }} data={pieData} categoryField="name" field="share" />
                            </ChartSeries>
                        </Chart>
                    </Card>
                </div>
            </div>
            <div style={{ margin: '25px' }}>
                <h3>KendoReact Chart</h3>
                <p>The KendoReact Charts provide high quality graphical data visualization options.</p>
                <p>They include a variety of chart types and styles that have extensive configuration options. This flexibility allows you to quickly and easily create the exact chart you need to fit your specific requirements for functionality and appearance.</p>
                <p>The KendoReact Chart Components are built from the ground up, specifically for React, so that you get high performance chart components that integrate with your React app and with the rest of your React UI components.</p>
                <p>For documentation and demos of all available Chart types (Bar, Line, Donut, Sparkline, StockChart etc), please visit the <a href="https://www.telerik.com/kendo-react-ui/components/charts/?utm_medium=product&utm_source=vs&utm_campaign=kendo-ui-react-branding-vs-ext">KendoReact Chart documentation page.</a> </p>
            </div>
        </React.Fragment>
    )
};

export default Chart_PageComponent;