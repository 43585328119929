import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';

export function MyCommandCell({ edit, remove, add, update, discard, cancel, editField }) {
    return (props) => {
        const { dataItem } = props;
        const inEdit: undefined | boolean = dataItem[editField];
        const isNewItem: boolean = dataItem.ProductID === undefined;

        return inEdit ? (
            <td className='k-command-cell'>
                <Button onClick={() => isNewItem ? add(dataItem) : update(dataItem)}>
                    {isNewItem ? 'Add' : 'Update'}
                </Button>
                <Button onClick={() => isNewItem ? discard(dataItem) : cancel(dataItem)}>
                    {isNewItem ? 'Discard' : 'Cancel'}
                </Button>
            </td>
        ) : (
            <td className='k-command-cell'>
                <Button themeColor='primary' onClick={() => edit(dataItem)}>
                    Edit
                </Button>
                <Button onClick={() => remove(dataItem)}>
                    Remove
                </Button>
            </td>
        );
    }
};
