import * as React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

import FormContainer from './FormContainer';
import { FormDatePicker, FormNumericTextBox, FormInput, FormCheckbox, FormMaskedTextBox, FormTextArea } from './FormComponents';
import { termsValidator, emailValidator, nameValidator, phoneValidator, guestsValidator, nightsValidator, arrivalDateValidator } from './validators';

const Form_PageComponent = (props) => {
    const [showDialog, setShowDialog] = React.useState<boolean>(false)

    const toggleDialog = () => {
        setShowDialog(!showDialog);
    }

    const handleSubmit = () => {
        setShowDialog(!showDialog);
    }

    return (
        <div className="container-fluid">
            {showDialog && <Dialog onClose={toggleDialog}>
                <p style={{ margin: "25px", textAlign: "center" }}>The form is successfully submitted!</p>
                <DialogActionsBar>
                    <Button onClick={toggleDialog}>OK</Button>
                </DialogActionsBar>
            </Dialog>}
            <div className='row my-4'>
                <FormContainer>
                    <Form onSubmit={handleSubmit} render={formRenderProps => <FormElement style={{
                        width: 400
                    }}>
                        <fieldset className={'k-form-fieldset'}>
                            <legend className={'k-form-legend'}>BOOK YOUR DREAM VACATION TODAY</legend>
                            <Field id={'fullName'} name={'fullName'} label={'Full Name'} component={FormInput} validator={nameValidator} />
                            <Field id={'phoneNumber'} name={'phoneNumber'} label={'Phone Number'} mask={'(999) 000-00-00-00'} hint={'Hint: Your active phone number.'} component={FormMaskedTextBox} validator={phoneValidator} />
                            <Field id={'email'} name={'email'} label={'Email'} hint={'Hint: Enter your personal email address.'} type={'email'} component={FormInput} validator={emailValidator} />
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Field id={'arrivalDate'} name={'arrivalDate'} label={'Arrival Date'} hint={'Hint: Should be greater than today'} component={FormDatePicker} validator={arrivalDateValidator} wrapperStyle={{
                                    width: '90%',
                                    marginRight: '18px'
                                }} />
                                <Field id={'nightsCount'} name={'nightsCount'} label={'Number of Nights'} format={'n0'} component={FormNumericTextBox} validator={nightsValidator} />
                            </div>
                            <Field id={'guestsCount'} name={'guestsCount'} label={'Number of Guests'} hint={'Hint: Maximum 5 guests.'} format={'n0'} component={FormNumericTextBox} validator={guestsValidator} />
                            <Field id={'comments'} name={'comments'} label={'Comments'} optional={true} component={FormTextArea} />
                            <span className={'k-form-separator'} />
                            <Field id={'terms'} name={'terms'} label={'I agree with terms and conditions'} component={FormCheckbox} validator={termsValidator} />
                            <div className="k-form-buttons">
                                <Button themeColor={'primary'} type={'submit'} disabled={!formRenderProps.allowSubmit}>
                                    Send Reservation Request
                                </Button>
                                <Button onClick={formRenderProps.onFormReset}>
                                    Clear
                                </Button>
                            </div>
                        </fieldset>
                    </FormElement>} />
                </FormContainer>
                <div className='col-12 col-lg-3 mt-3 mt-lg-0'>
                    <h3>KendoReact Forms</h3>
                    <p>KendoReact includes a wide offering of UI components that can be used to build forms, including CSS classes to easily create and structure gorgeous forms.</p>
                    <p>The required inputs get validated upon form submission and if the validation fails, the form submission is prevented. Out of the box, KendoReact delivers components which support the HTML5 form validation and also provide props for configuring a set of minimal requirements for a component to be in a valid state.</p>
                    <p>For documentation and demos of the many form-friendly components please visit their documentation (<a href="https://www.telerik.com/kendo-react-ui/components/dateinputs/?utm_medium=product&utm_source=vs&utm_campaign=kendo-ui-react-branding-vs-ext"> Date Inputs</a>, <a href="https://www.telerik.com/kendo-react-ui/components/dropdowns/?utm_medium=product&utm_source=vs&utm_campaign=kendo-ui-react-branding-vs-ext"> DropDowns</a>, <a href="https://www.telerik.com/kendo-react-ui/components/inputs/?utm_medium=product&utm_source=vs&utm_campaign=kendo-ui-react-branding-vs-ext">Inputs</a> etc).</p>
                </div>
            </div>
        </div>
    )
}

export default Form_PageComponent;
