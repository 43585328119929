import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.scss';

import Blank from "./components/Blank";

import Chart from "./components/Chart";

import Form from "./components/Form";

import Grid from "./components/Grid";

function App() {
  return (
    <Router>
        <div className="content">
          <Header projectName={'kendoReact'} />
          <Route exact path="/" component={Home} />
  <Route path = "/Blank" component={Blank} />
  <Route path = "/Chart" component={Chart} />
  <Route path = "/Form" component={Form} />
  <Route path = "/Grid" component={Grid} />
        </div>
        <div className="footer">
          <Footer />
        </div>
    </Router>
  );
}

export default App;
