import * as React from 'react';
import { sampleProducts, SampleProductsType } from '../common/sample-products';
import { MyCommandCell } from './myCommandCell';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { process } from '@progress/kendo-data-query';

interface LocalizedDataType {
    text: string;
    value: null | boolean;
}

export const DropDownCell = props => {
    const localizedData: LocalizedDataType[] = [{
        text: 'yes',
        value: true
    }, {
        text: 'no',
        value: false
    }, {
        text: '(empty)',
        value: null
    }];

    const { dataItem } = props;

    const field: string = props.field || '';
    const dataValue: boolean = dataItem[field] === null ? '' : dataItem[field];

    const handleChange = e => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value
            });
        }
    };

    return (
        <td>
            {dataItem.inEdit ? <DropDownList style={{
                width: "100px"
            }} onChange={handleChange} value={localizedData.find(c => c.value === dataValue)} data={localizedData} textField="text" /> : dataValue.toString()}
        </td>
    );
};

interface DataItem {
    ProductID?: number;
    ProductName?: string;
    SupplierID?: number;
    CategoryID?: number;
    QuantityPerUnit?: string;
    UnitPrice?: number;
    UnitsInStock?: number;
    UnitsOnOrder?: number;
    ReorderLevel?: number;
    Discontinued?: boolean;
    Category?: {
      CategoryID: number;
      CategoryName: string;
      Description: string;
    };
    FirstOrderedOn?: Date;
    inEdit?: boolean;
}

interface DataStateType {
    skip: number;
    take: number;
}

const Grid_PageComponent = (props) => {
    const editField: string = "inEdit";
    const [data, setData] = React.useState<DataItem[]>(sampleProducts);
    // eslint-disable-next-line
    const [dataState, setDataState] = React.useState<DataStateType>({ skip: 0, take: 10 })

    const generateId: (data: DataItem[]) => number = data => data.reduce((acc, current) => Math.max(acc, current.ProductID ?? 0), 0) + 1;

    const removeItem = (data: DataItem[], item: DataItem) => {
        let index: number = data.findIndex(p => p === item || (item.ProductID && p.ProductID === item.ProductID));
        if (index >= 0) {
            data.splice(index, 1);
        }
    }

    const enterEdit = (dataItem: DataItem) => {
        setData(data.map(item =>
            item.ProductID === dataItem.ProductID ?
                { ...item, inEdit: true } : item
        ));
    }

    const remove = (dataItem: DataItem) => {

        const newData = [...data];
        removeItem(newData, dataItem);
        removeItem(sampleProducts, dataItem);
        setData([...newData]);
    }

    const add = (dataItem: DataItem) => {
        dataItem.inEdit = undefined;
        dataItem.ProductID = generateId(sampleProducts);

        sampleProducts.unshift(dataItem as SampleProductsType);
        setData([...data])
    }

    const discard = (dataItem: DataItem) => {
        const newData = [...data];
        removeItem(newData, dataItem);

        setData(newData);
    }

    const update = (dataItem: DataItem) => {
        const newData: DataItem[] = [...data]
        const updatedItem: DataItem = { ...dataItem, inEdit: undefined };

        updateItem(newData, updatedItem);
        updateItem(sampleProducts, updatedItem);

        setData(newData);
    }

    const cancel = (dataItem: DataItem) => {
        const originalItem: DataItem = sampleProducts.find(p => p.ProductID === dataItem.ProductID)!;
        const newData: DataItem[] = data.map(item => item.ProductID === originalItem.ProductID ? originalItem : item);

        setData(newData);
    }

    const updateItem = (data: DataItem[], item: DataItem) => {
        let index: number = data.findIndex(p => p === item || (item.ProductID && p.ProductID === item.ProductID));
        if (index >= 0) {
            data[index] = { ...item };
        }
    }

    const itemChange = (event) => {
        const newData: DataItem[] = data.map(item =>
            item.ProductID === event.dataItem.ProductID ?
                { ...item, [event.field]: event.value } : item
        );
        setData(newData);
    }

    const addNew = () => {
        const newDataItem: DataItem = { inEdit: true, Discontinued: false };
        setData([newDataItem, ...data]);
    }

    let CommandCell = MyCommandCell({
        edit: enterEdit,
        remove: remove,

        add: add,
        discard: discard,

        update: update,
        cancel: cancel,

        editField: editField
    });

    return (
        <div className="container-fluid">
            <div className='row my-4'>
                <div className='col-12 col-lg-9 border-right'>
                    <Grid
                        data={process(data, dataState)}
                        onItemChange={itemChange}
                        editField={editField}
                        dataItemKey={'ProductID'}
                    // pageable // uncomment to enable paging
                    // sortable // uncomment to enable sorting
                    // filterable // uncomment to enable filtering
                    // onDataStateChange={(e) => setDataState(e.data)} // uncomment to enable data operations
                    // {...dataState} // uncomment to enable data operations
                    >
                        <GridToolbar>
                            <Button title="Add new" className="k-button-solid-primary" onClick={addNew}>
                                Add new
                            </Button>
                        </GridToolbar>
                        <Column field="ProductID" title="Id" width="50px" editable={false} />
                        <Column field="ProductName" title="Product Name" />
                        <Column field="FirstOrderedOn" title="First Ordered" editor="date" format="{0:d}" />
                        <Column field="UnitsInStock" title="Units" editor="numeric" />
                        <Column field="Discontinued" title="Discontinued" cell={DropDownCell} />
                        <Column cell={CommandCell} width="240px" />
                    </Grid>
                </div>
                <div className='col-12 col-lg-3 mt-3 mt-lg-0'>
                    <h3>KendoReact Grid</h3>
                    <p>The KendoReact Data Grid (Table) provides 100+ ready-to-use features covering everything from paging, sorting, filtering, editing, and grouping to row and column virtualization, export to PDF and Excel and accessibility.</p>
                    <p>For documentation and demos of all available Grid features (filtering, sorting, paging, editing etc), please visit the <a href="https://www.telerik.com/kendo-react-ui/components/grid/?utm_medium=product&utm_source=vs&utm_campaign=kendo-ui-react-branding-vs-ext">KendoReact Grid documentation page.</a> </p>
                </div>
            </div>
        </div >
    );
}

export default Grid_PageComponent;
